<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100px"
    >
      <div id="customer-list">
        <v-card>
          <v-card-title>会員検索</v-card-title>
          <!-- <v-divider></v-divider>
          <v-row class="px-2 ma-0">
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="firstName"
                outlined
                dense
                placeholder="セイ"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="lastName"
                outlined
                dense
                placeholder="メイ"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="email"
                outlined
                dense
                placeholder="メールアドレス"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="telephoneNum"
                outlined
                dense
                placeholder="電話番号"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-btn
              color="primary"
              class="ml-auto mr-4"
              @click="conditionalSearch"
            >
              <v-icon
                size="17"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>検索</span>
            </v-btn>
          </v-row> -->
          <!--
          <v-divider class="mt-4"></v-divider> -->
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="search"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>
          <v-data-table
            v-model="selectedList"
            item-key="mbr_id"
            :items="tableList"
            show-select
            single-select
            :headers="headers"

            sort-by
            class="elevation-0"
            :search="search"
          >
            <template v-slot:[`item.mbr_status`]="{ item }">
              <div>
                <span>{{ statuss[item.mbr_status] ? statuss[item.mbr_status].text : '' }}</span>
              </div>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <overlays
        :is-absolute="true"
        :is-show="absoluteOverlayStatus"
      ></overlays>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import {
  mdiCalendar,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiExportVariant,
  mdiAutorenew,
  mdiPlus,
  mdiClipboardArrowDownOutline,
  mdiClipboardArrowUpOutline,
  mdiDotsHorizontal,
} from '@mdi/js'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    Overlays,
  },
  data: () => ({

    dialog: false,
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    firstName: '',
    lastName: '',
    email: '',
    telephoneNum: '',
    search: '',
    searchText: '',
    arrBfo: [],
    selectedList: [],
    addCusList: [],
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiAutorenew,
      mdiPlus,
      mdiClipboardArrowDownOutline,
      mdiClipboardArrowUpOutline,
      mdiDotsHorizontal,
    },
    tableList: [],
    headers: [
      {
        text: 'ID',
        value: 'mbr_id',
        align: 'left',
        width: '8%',
        fixed: true,
      },
      {
        text: '会員コード',
        value: 'mbr_code',
        align: 'left',
        width: '12%',
        fixed: true,
      },

      {
        text: '氏名',
        value: 'mbr_name',
        width: '15%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '氏名(カナ)',
        value: 'mbr_name_kana',
        width: '15%',
        align: 'left',
        sortable: true,
        fixed: false,
      },

      {
        text: 'メールアドレス',
        value: 'mbr_email',
        width: '15%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '電話番号',
        value: 'mbr_mobile',
        width: '10%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '会員状態',
        value: 'ms_desc',
        width: '20%',
        align: 'center',
        sortable: true,
        fixed: false,
      },

    ],
  }),
  computed: {
    ...mapState('memberStore', ['memberList']),
    ...mapState('app', ['absoluteOverlayStatus']),
  },
  watch: {
    selectedList(after) {
      this.addCusList = after
    },
  },
  created() {

  },
  methods: {
    ...mapActions('memberStore', ['loadMemberList']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus']),

    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadMemberList(this.$route.query).then(() => {
        this.tableList = [
          ...this.memberList,
        ]
        this.tableList.forEach((el, i) => {
          this.tableList[i].mbr_name = `${el.mbr_sei} ${el.mbr_mei}`
          this.tableList[i].mbr_name_kana = `${el.mbr_sei_kana} ${el.mbr_mei_kana}`
        })
      }).finally(() => {
        this.setAbsoluteOverlayStatus(false)
      })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.firstName = this.$route.query.mbr_sei_kana ?? ''
      this.lastName = this.$route.query.mbr_mei_kana ?? ''
      this.email = this.$route.query.mbr_email ?? ''
      this.telephoneNum = this.$route.query.mbr_mobile ?? ''
      // eslint-disable-next-line radix
      this.status = this.$route.query.mbr_status ? parseInt(this.$route.query.mbr_status) : ''
    },

    openForm() {
      this.dialog = true
      this.loadQuery()
      this.changeRouteQuery()
      this.loadData()
    },

    goBack() {
      this.dialog = false
      this.$emit('add_cuslist', this.addCusList)
      this.showSelect = false
    },
    conditionalSearch() {
      const query = {
      }
      if (this.firstName) {
        query.firstName = this.firstName
      }
      if (this.lastName) {
        query.lastName = this.lastName
      }
      if (this.status || this.status === 0) {
        if (this.status === 0) {
          query.status = '入会'
        } else if (this.status === 1) {
          query.status = '休止'
        } else if (this.status === 2) {
          query.status = '退会'
        }
      }
      if (this.email) {
        query.email = this.email
      }
      if (this.telephoneNum) {
        query.telephoneNum = this.telephoneNum
      }
      this.$router.push({
        path: this.$route.path, query,
      })
      this.loadData()
    },
    getStatus(status) {
      if (status === 0) {
        return '入会'
      }
      if (status === 1) {
        return '休止'
      }
      if (status === 2) {
        return '退会'
      }

      return ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
