var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","disabled":_vm.btnDisabled,"elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.openForm.apply(null, arguments)}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsHorizontal)+" ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1100px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{attrs:{"id":"customer-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("送付先選択")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"item-key":"addr_id","items":_vm.tableList,"show-select":"","single-select":"","headers":_vm.headers,"add-adr-list":"getaddAdrList","sort-by":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.addr_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"javascript:void(0);"}},'a',attrs,false),on),[_vm._v(_vm._s(item.addr_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.addr_company)+" "+_vm._s(item.addr_department))])])]}},{key:"item.pref_name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"javascript:void(0);"}},'a',attrs,false),on),[_vm._v(_vm._s(item.pref_name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.addr_addr1)+" "+_vm._s(item.mbr_address2))])])]}}],null,true),model:{value:(_vm.selectedList),callback:function ($$v) {_vm.selectedList=$$v},expression:"selectedList"}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.goBack}},[_c('span',[_vm._v("OK")])]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v("キャンセル")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }