<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icon }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color: red"
          >削除</span>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="CommodityProductList"
          sort-by
          class="elevation-1"
        >
        </v-data-table>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['icon', 'crushedId'],
  data: () => ({
    dateMenu: false,
    submitStatus: false,
    dialog: false,
    item: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    headers: [
      {
        text: '注文日',
        value: 'commodityproduct_id',
        align: 'left',
        width: '15%',
        fixed: true,
      },
      {
        text: 'お届け先',
        value: 'product_img',
        align: 'center',
        width: '23%',
        sortable: false,
        fixed: true,
      },
      {
        text: '注文者',
        align: 'center',
        sortable: true,
        value: 'commodityproduct_name',
        width: '23%',
        fixed: true,
      },
      {
        text: '注文番号',
        value: 'commodityproduct_price',
        width: '23%',
        align: 'center',
        sortable: true,
        fixed: false,
      },
      {
        text: '在庫',
        value: 'commodityproduct_stock',
        width: '15%',
        align: 'right',
        sortable: true,
        fixed: true,
      },
    ],

    CommodityProductList: [
      {
        commodityproduct_id: '1',
        commodityproduct_name: 'ドリップバッグ モカ',
        commodityproduct_price: '7,980',
        commodityproduct_stock: '在庫',
        commodityproduct_quantity: '8',
        commodityproduct_sku: 'AB123789-1',
        commodityproduct_order: '2',
        commodityproduct_date: '2022-10-13',
        commodityproduct_staus: 'published',
      },
    ],
  }),
  methods: {
    deleteItem(item) {
      this.apierror.messages = []
      this.submitStatus = true
      this.deleteCrushingActual(item.crushed_id)
        .then(() => {
          this.submitStatus = false
          this.dialog = false
          this.$emit('deleted')
        })
        .catch(error => {
          this.submitStatus = false
          this.apierror.status = 'error'
          this.apierror.messages = error.response.data.message
          console.log('apierror', this.apierror)
        })
    },

    openForm() {
      this.dialog = true
    },
    closeForm() {
      this.$emit('cancel')
      this.apierror.messages = []
      this.dialog = false
      this.item = {
      }
    },
  },
}
</script>
<style scoped>
/* .theme--light.v-label--is-disabled {
  color: rgba(241, 104, 12, 0.87)  !important;
}
.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(15, 218, 140, 0.87)  !important;
} */
</style>
