<template>
  <div>
    <v-card>
      <v-card-title>定期便注文スケジュール新規</v-card-title>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 py-0 pt-2">
        <v-col
          cols="12"
          md="2"
        >
          <label>会員ID<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.OrderForm.mbr_id.$model"
            :error-messages="ordMbrIdErrors"
            readonly
            outlined
            dense
            placeholder="会員ID"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <Customer
            @add_cuslist="getaddCusList"
          ></Customer>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>会員氏名<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.OrderForm.mbr_name.$model"
            :error-messages="ordMbrNameErrors"
            readonly
            outlined
            dense
            placeholder="会員氏名"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label>送付先<span style="color: red">*</span></label>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="$v.OrderForm.addr_name.$model"
            :error-messages="ordAdrNameErrors"
            readonly
            outlined
            dense
            placeholder="送付先"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <Addresses
            :adr-id="OrderForm.mbr_id"
            @add_adrlist="getaddAdrList"
          ></Addresses>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">注文日</label><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="py-0 my-0"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="ordDay"
                :error-messages="ordDayErrors"
                placeholder="注文日"
                color="#fa7a00"
                dense
                hide-details="auto"
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="ordDay"
              color="#fa7a00"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 py-0 pb-2">
        <v-col
          cols="12"
          md="2"
        >
          <label style="font-size:16px;color:#636363">注文時間</label><span style="color: red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="ordHour"
            :items="ordHours"
            :error-messages="ordHourErrors"
            placeholder="注文時"
            color="#fa7a00"
            outlined
            dense
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="ordTime"
            :error-messages="ordTimeErrors"
            :items="ordTimes"
            placeholder="注文分"
            color="#fa7a00"
            outlined
            dense
            hide-details="auto"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 py-1">
        <v-col
          cols="12"
          md="1"
        >
          <label>カート</label>
        </v-col>
        <v-col
          v-if="tableErrors"
          cols="12"
          md="1"
          style="color:red;font-size:smaller;text-align:center"
        >
          <label>必須項目</label>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="1"
          class="mr-3 py-0"
        >
          <ScheduledProduct
            :list="catList"
            @add_prolist="getaddProList"
          ></ScheduledProduct>
        </v-col>
      </v-row>
      <v-data-table
        ref="Contracts"
        class="class-on-data-table elevation-0"
        :error-messages="tableListErrors"
        :headers="newHeaders"
        :items="catList"
      >
        <template v-slot:[`item.product_img`]="{ item }">
          <div class="p-2">
            <v-img
              :src="item.product_img ? item.product_img : require('@/assets/images/noimage.jpg')"
              max-height="50"
              max-width="50"
              class="me-3"
            ></v-img>
          </div>
        </template>
        <template v-slot:[`item.product_name`]="{ item }">
          <v-tooltip v-if="item.product_name" bottom>
            <template v-slot:activator="{ on, attrs }">
              <p
                class="class-with-title"
                style="margin: 0; width:14rem; overflow: hidden; text-overflow:ellipsis;white-space: nowrap;"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.product_name }}
              </p>
            </template>
            <span>{{ item.product_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.sum_min`]="{ item }">
          <v-icon
            class="mr-2 pr-0"
            color="primary"
            @click="decrement(item)"
          >
            {{ icons.mdiMinusBox }}
          </v-icon>
        </template>
        <template v-slot:[`item.order_qty`]="{ item }">
          <v-text-field
            v-model="item.order_qty"
            style="padding-right:0px"
            readonly
            reverse
            outlined
            dense
            hide-details
            class="text-right"
          ></v-text-field>
        </template>
        <template v-slot:[`item.sum_plus`]="{ item }">
          <v-icon
            class="mr-2"
            color="primary"
            @click="increment(item)"
          >
            {{ icons.mdiPlusBox }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            color="red"
            @click="remove(item)"
          >
            {{ icons.mdiMinusCircle }}
          </v-icon>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <label>{{ item.price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`item.cat_ProAllPrice`]="{ item }">
          <label>{{ item.cat_ProAllPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              style="background: #fafafa"
              :colspan="6"
            >
              <label>合計価格(円)</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ getSumPrice.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }} 円</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="1"
            ></td>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <label for="note">備考</label>
          </v-col>
          <v-col
            cols="12"
            md="11"
          >
            <v-textarea
              name="note"
              hide-details="auto"
              outlined
              :counter="100"
              dense
              rows="5"
              placeholder="備考"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          color="primary"
          class="mr-5"
          :loading="submitStatus"
          @click="submit(OrderForm)"
        >
          <span>保存</span>
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          :to="{ name: 'scheduledsubscription-list', params: { query: this.$route.query } }"
        >
          <span>キャンセル</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mapActions,
} from 'vuex'
import {
  required,
} from 'vuelidate/lib/validators'
import {
  mdiCreditCardOutline,
  mdiPlus,
  mdiMinus,
  mdiDotsHorizontal,
  mdiMinusCircle,
  mdiPlusCircle,
  mdiMinusBox,
  mdiPlusBox,
} from '@mdi/js'

// import NumberChange from './NumberChange.vue'
import Customer from '@/components/Customer.vue'
import Addresses from '@/components/Addresses.vue'
import ScheduledProduct from '@/views/bimi/scheduledsubscription/ScheduledProduct.vue'

export default {
  components: {

    // NumberChange,
    Customer,
    Addresses,
    ScheduledProduct,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    bpm: 1,
    dateMenu: false,
    changeFlag: false,
    newCusList: [],
    newCusId: '',
    newCusName: '',
    newProList: [],
    newCatList: [],
    getSumPrice: 0,
    alert: false,
    dialog: false,
    newList: [],
    tableErrors: false,
    icons: {
      mdiCreditCardOutline,
      mdiPlus,
      mdiMinus,
      mdiDotsHorizontal,
      mdiMinusCircle,
      mdiPlusCircle,
      mdiMinusBox,
      mdiPlusBox,
    },
    submitStatus: false,
    OrderForm: {
      mbr_id: '',
      order_id: '',
      order_no: '',
      mbr_name: '',
      order_dt: '',
      addr_name: '',
      addr_zip: '',
      addr_addr1: '',
      addr_addr2: '',
      addr_id: '',
      details: [],

      // order_total: '',
    },
    dateStartMenu: '',
    ordDay: '',
    ordHour: '',
    ordHours: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ],
    ordTime: '',
    ordTimes: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '51',
      '52',
      '53',
      '54',
      '55',
      '56',
      '57',
      '58',
      '59',
    ],
    newHeaders: [
      {
        text: 'No.',
        value: 'cat_No',
        sortable: false,
        align: 'left',
        width: '6%',
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'product_code',
        sortable: false,
        align: 'left',
        width: '13%',
        fixed: true,
      },
      {
        text: '商品名',
        value: 'product_name',
        sortable: false,
        align: 'left',
        width: '18%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'sum_min',
        sortable: false,
        align: 'right',
        width: '2%',
        fixed: true,
      },
      {
        text: '数量',
        value: 'order_qty',
        sortable: false,
        align: 'right',
        width: '10%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'sum_plus',
        sortable: false,
        align: 'right',
        width: '4%',
        fixed: true,
      },
      {
        text: '商品価格(円)',
        value: 'price',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '商品合計(円)',
        value: 'cat_ProAllPrice',
        sortable: false,
        align: 'right',
        width: '12%',
        fixed: true,
      },
      {
        text: '  ',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '4%',
        fixed: true,
      },
    ],
    catList: [
    ],
    adrName: '',
  }),
  validations: {
    OrderForm: {
      mbr_id: {
        required,
      },
      mbr_name: {
        required,
      },
      addr_name: {
        required,
      },
    },
    catList: {
      required,
    },
    ordDay: {
      required,
    },
    ordHour: {
      required,
    },
    ordTime: {
      required,
    },
  },
  computed: {
    ordMbrIdErrors() {
      const errors = []
      if (!this.$v.OrderForm.mbr_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.OrderForm.mbr_id.required && errors.push('必須項目')

      return errors
    },
    ordMbrNameErrors() {
      const errors = []
      if (!this.$v.OrderForm.mbr_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.OrderForm.mbr_name.required && errors.push('必須項目')

      return errors
    },
    ordAdrNameErrors() {
      const errors = []
      if (!this.$v.OrderForm.addr_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.OrderForm.addr_name.required && errors.push('必須項目')

      return errors
    },
    ordDayErrors() {
      const errors = []
      if (!this.$v.ordDay.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ordDay.required && errors.push('必須項目')

      return errors
    },
    ordHourErrors() {
      const errors = []
      if (!this.$v.ordHour.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ordHour.required && errors.push('必須項目')

      return errors
    },
    ordTimeErrors() {
      const errors = []
      if (!this.$v.ordTime.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.ordTime.required && errors.push('必須項目')

      return errors
    },
    tableListErrors() {
      const errors = []
      if (!this.$v.catList.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.catList.required && errors.push('必須項目')

      return errors
    },
  },
  watch: {
    // mbr_id(newvalue, oldvalue) {
    //   console.log('IIID', newvalue, oldvalue)
    //   if (newvalue !== oldvalue) {
    //     this.adrName = ''
    //   }
    // },
    'OrderForm.mbr_id': {
      handler(newvalue, oldvalue) {
        // console.log('IIID', newvalue, oldvalue)
        if (newvalue !== oldvalue) {
          this.OrderForm.addr_name = ''
        }
      },
    },
  },
  created() {
    this.ordDay = new Date().toISOString().substring(0, 10)
    this.ordHour = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(11, 13)
    this.ordTime = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(14, 16)
  },
  methods: {
    ...mapActions('orderStore', ['createOrder']),
    getaddCusList(addCusList) {
      if (addCusList.length === 1) {
        this.newCusList = addCusList
        this.OrderForm.mbr_id = this.newCusList[0].mbr_id
        this.OrderForm.mbr_name = this.newCusList[0].mbr_sei + this.newCusList[0].mbr_mei
      } else {
        this.OrderForm.mbr_id = ''
        this.OrderForm.mbr_name = ''
      }
    },
    getaddAdrList(addAdrList) {
      // console.log('addAdrList', addAdrList)
      if (addAdrList.length === 1) {
        this.OrderForm.addr_name = addAdrList[0].addr_sei + addAdrList[0].addr_mei
        this.OrderForm.addr_zip = addAdrList[0].addr_zip
        this.OrderForm.addr_addr1 = addAdrList[0].addr_addr1
        this.OrderForm.addr_addr2 = addAdrList[0].addr_addr2
        this.OrderForm.addr_id = addAdrList[0].addr_id
      } else {
        this.OrderForm.addr_name = ''
        this.OrderForm.addr_zip = ''
        this.OrderForm.addr_addr1 = ''
        this.OrderForm.addr_addr2 = ''
        this.OrderForm.addr_id = ''
      }
    },

    getaddProList(addProList) {
      // console.log(addProList)
      this.catList = []
      this.getSumPrice = 0
      this.newList = addProList
      for (let i = 0; i < this.newList.length; i += 1) {
        const catNo = this.catList.length + 1
        this.catList.push({
          cat_No: catNo,
          product_img: this.newList[i].product_img,
          product_code: this.newList[i].product_code,
          product_name: this.newList[i].product_name,
          order_qty: this.newList[i].order_qty ? this.newList[i].order_qty : 1,
          price: this.newList[i].product_tip,
          sell_price: this.newList[i].sell_price,
          cat_ProAllPrice: this.newList[i].order_qty ? this.newList[i].order_qty * this.newList[i].product_tip : 1 * this.newList[i].product_tip,
        })
        this.getSumPrice += this.catList[i].cat_ProAllPrice

        // this.OrderForm.order_total = this.getSumPrice
      }
      this.OrderForm.details = addProList.map(mal => {
        const item = {
        }
        item.product_code = mal.product_code
        item.product_name = mal.product_name
        item.order_qty = mal.item_qty ? mal.item_qty : 1
        item.order_tip = mal.product_tip

        return item
      })

      // console.log('this.OrderForm.details', this.OrderForm.details)
    },

    decrement(item) {
      const index = this.catList.findIndex(el => el.cat_No === item.cat_No)
      if (this.catList[index].order_qty > 1 && index >= 0) {
        this.catList[index].order_qty -= 1
        this.OrderForm.details[index].order_qty = this.catList[index].order_qty
        this.catList[index].cat_ProAllPrice = this.catList[index].price * this.catList[index].order_qty
      }
      this.getSumPrice = 0
      for (let i = 0; i < this.catList.length; i += 1) {
        // console.log(this.catList[i].cat_ProAllPrice)
        this.getSumPrice += this.catList[i].cat_ProAllPrice

        // this.OrderForm.order_total = this.getSumPrice
      }
    },

    increment(item) {
      const index = this.catList.findIndex(el => el.cat_No === item.cat_No)
      if (index >= 0) {
        this.catList[index].order_qty += 1
        this.OrderForm.details[index].order_qty = this.catList[index].order_qty
      }
      this.catList[index].cat_ProAllPrice = this.catList[index].price * this.catList[index].order_qty
      this.getSumPrice = 0
      for (let i = 0; i < this.catList.length; i += 1) {
        // console.log(this.catList[i].cat_ProAllPrice)
        this.getSumPrice += this.catList[i].cat_ProAllPrice

        // this.OrderForm.order_total = this.getSumPrice
      }
    },

    remove(item) {
      if (this.catList.indexOf(item) !== -1) {
        this.catList.splice(this.catList.indexOf(item), 1)
        const orderContracts = []
        this.catList.forEach((v, i) => {
          orderContracts[i] = v
          orderContracts[i].cat_No = i + 1
        })
      } else {
        console.log('remove-----> item does not exist')
      }
      this.getSumPrice = 0
      for (let i = 0; i < this.catList.length; i += 1) {
        // console.log(this.catList[i].cat_ProAllPrice)
        this.getSumPrice += this.catList[i].cat_ProAllPrice

        // this.OrderForm.order_total = this.getSumPrice
      }
      const index = this.OrderForm.details.findIndex(or => or.product_code === item.product_code)
      if (index >= 0) this.OrderForm.details.splice(index, 1)
    },

    jumpToListPage() {
      this.$router.push({
        path: '/scheduledsubscription-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    submit() {
      this.jumpToListPage()
    },

    // submit(OrderForm) {
    //   this.OrderForm.order_dt = `${this.ordDay} ${this.ordHour}:${this.ordTime}`

    //   // console.log('OrderForm', OrderForm)

    //   this.$v.$touch()
    //   if (!this.$v.$invalid) {
    //     this.submitStatus = true
    //     const submitForm = {
    //       ...OrderForm,
    //     }
    //     this.createOrder(submitForm)
    //       .then(() => {
    //         this.submitStatus = false
    //         this.jumpToListPage()
    //       })
    //       .catch(error => {
    //         console.log(error)
    //         this.submitStatus = false

    //         this.apierror.status = 'error'
    //         this.apierror.code = error.response.data.code
    //         this.apierror.messages = error.response.data.message

    //         console.log('apierror', this.apierror)
    //       })
    //   } else {
    //     window.scrollTo(0, 0)
    //     if (this.catList.length === 0) {
    //       this.tableErrors = true
    //     } else {
    //       this.tableErrors = false
    //     }
    //     this.apierror.messages = []
    //     this.apierror.status = 'error'
    //     this.apierror.messages.push('赤い部分の情報を修正してください')
    //     console.log('error submit!!')
    //   }
    // },
  },
}
</script>
