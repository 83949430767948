<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      :disabled="btnDisabled"
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100px"
    >
      <div id="customer-list">
        <v-card>
          <v-card-title>送付先選択</v-card-title>
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="search"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
            <v-spacer></v-spacer>
          </v-card-text>
          <v-data-table
            v-model="selectedList"
            item-key="addr_id"
            :items="tableList"
            show-select
            single-select
            :headers="headers"
            add-adr-list="getaddAdrList"
            sort-by
            class="elevation-0"
            :search="search"
          >
            <template v-slot:[`item.addr_name`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    href="javascript:void(0);"
                    v-on="on"
                  >{{ item.addr_name }}</a>
                </template>
                <span>{{ item.addr_company }} {{ item.addr_department }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.pref_name`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-bind="attrs"
                    href="javascript:void(0);"
                    v-on="on"
                  >{{ item.pref_name }}</a>
                </template>
                <span>{{ item.addr_addr1 }} {{ item.mbr_address2 }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'

import {
  mdiDotsHorizontal,
} from '@mdi/js'

export default {
  props: ['mbrId', 'addrIdList', 'btnDisabled'],
  data: () => ({
    dialog: false,
    search: '',
    selectedList: [],
    addAdrList: [],
    icons: {
      mdiDotsHorizontal,
    },
    tableList: [],
    headers: [
      {
        text: 'ID',
        value: 'addr_id',
        align: 'left',
        width: '10%',
        fixed: true,
      },
      {
        text: '送付先氏名',
        align: 'left',
        sortable: true,
        value: 'addr_name',
        width: '20%',
        fixed: true,
      },
      {
        text: '送付先氏名(カナ)',
        value: 'addr_name_kana',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '電話番号',
        value: 'addr_telno',
        width: '30%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '都道府県',
        value: 'pref_name',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('memberStore', ['selectedAddresses']),
    ...mapState('app', ['absoluteOverlayStatus']),
  },
  watch: {
    selectedList(after) {
      this.addAdrList = after
    },

    dialog(value) {
      console.log('value,addr:', value, this.addrIdList)
      if (value && this.addrIdList) {
        console.log('map')
        this.selectedList = this.addrIdList.map(l => {
          const item = this.tableList.find(tl => tl.addr_id === l.addr_id)

          return item
        })
      }
    },
  },
  created() {

  },
  methods: {
    ...mapActions('memberStore', ['loadAddresses']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadAddresses(this.mbrId).then(() => {
        this.tableList = [
          ...this.selectedAddresses,
        ]

        this.tableList.forEach((el, i) => {
          this.tableList[i].addr_name = `${el.addr_sei}${' '}${el.addr_mei}`
          this.tableList[i].addr_name_kana = `${el.addr_sei_kana}${' '}${el.addr_mei_kana}`
        })

        this.dialog = true
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    openForm() {
      this.loadData()
    },
    goBack() {
      this.dialog = false
      console.log('address:', this.addAdrList)
      this.$emit('add_adrlist', this.addAdrList)
      this.showSelect = false
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
