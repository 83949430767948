<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100px"
    >
      <div id="products-list">
        <v-card>
          <v-card-title>定期便検索</v-card-title>
          <v-divider></v-divider>
          <v-row class="px-2 ma-0">
            <!-- <v-col
              cols="4"
            >
              <v-autocomplete
                v-model="item_code"
                :items="itemCodes"
                placeholder="単品コード"
                outlined
                clearable
                dense
                hide-details
                class="me-3 mb-4"
              ></v-autocomplete>
            </v-col> -->
            <!-- <v-col
              cols="4"
            >
              <v-autocomplete
                v-model="set_code"
                :items="setCodes"
                placeholder="セットコード"
                outlined
                clearable
                dense
                hide-details
                class="me-3 mb-4"
              ></v-autocomplete>
            </v-col> -->
            <v-col
              cols="4"
            >
              <v-autocomplete
                v-model="subs_code"
                :items="subsCodes"
                placeholder="定期便コード"
                outlined
                clearable
                dense
                hide-details
                class="me-3 mb-4"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-data-table
            v-model="selectedList"
            show-select
            :headers="headers"
            :items="tableList"
            add-pro-list="getaddProList"
            item-key="product_code"
            class="elevation-0"
            :search="search"
          >
            <template v-slot:[`item.product_name`]="{ item }">
              <v-tooltip v-if="item.product_name" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    class="class-with-title"
                    style="margin: 0; width:14rem; overflow: hidden; text-overflow:ellipsis;white-space: nowrap;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.product_name }}
                  </p>
                </template>
                <span>{{ item.product_name }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.product_tip`]="{ item }">
              <label>{{ item.product_tip.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}</label>
            </template>
            <template v-slot:[`item.product_type`]="{ item }">
              <div>
                <span>{{ statuss[item.product_type - 1] ? statuss[item.product_type - 1].text : '' }}</span>
              </div>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
/* eslint-disable */
import { mdiCalendar, mdiMagnify, mdiPencil, mdiDelete, mdiExportVariant, mdiAutorenew, mdiPlus, mdiClipboardArrowDownOutline, mdiClipboardArrowUpOutline, mdiDotsHorizontal} from '@mdi/js'
import DeleteForm from '@/views/bimi/product/item/ProductsDelete.vue'
/* eslint-disable */

export default {
  props: ['list'],
  components: {
    DeleteForm,
  },
  data: () => ({
    dialog: false,
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    arrBfo:[],
    search: '',
    selectedList: [],
    addProList: [],
    tableList: [],
    id:'',
    item_code: '',
    set_code:'',
    subs_code:'',
    icons: {
      mdiCalendar,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiAutorenew,
      mdiPlus,
      mdiClipboardArrowDownOutline,
      mdiClipboardArrowUpOutline,
      mdiDotsHorizontal,
    },
    statuss: [
      {
        text: '単品', value: 1,
      },
      {
        text: 'セット', value: 2,
      },
      {
        text: '定期便', value: 3,
      },
    ],
    headers: [
      // {
      //   text: 'イメージ',
      //   value: 'product_img',
      //   align: 'left',
      //   width: '18%',
      //   sortable: false,
      //   fixed: true,
      // },
      {
        text: '商品コード',
        value: 'product_code',
        width: '21%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '商品名',
        align: 'left',
        sortable: true,
        value: 'product_name',
        width: '27%',
        fixed: true,
      },
      {
        text: '商品タイプ',
        value: 'product_type',
        width: '22%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      {
        text: '商品価格(円)',
        value: 'product_tip',
        width: '22%',
        align: 'right',
        sortable: true,
        fixed: false,
      },
      // {
      //   text: '割引価格(円)',
      //   value: 'sell_price',
      //   width: '18%',
      //   align: 'right',
      //   sortable: true,
      //   fixed: false,
      // },
    ],
  }),
  computed: {
    ...mapState('productStore', ['ProductList']),
    ...mapState('itemStore', ['itemCodes']),
    ...mapState('subscriptionStore', ['subsCodes']),
  },
  watch: {
    //単品コード
    // item_code(newValue) {
    //   this.changeRouteQuery('item_code', newValue)
    //   this.loadData()
    //   if (newValue !== null && newValue !== '') {
    //     this.set_code = ''
    //     this.subs_code = ''
    //   }
    // },

    //セットコード
    // set_code(newValue) {
    //   this.changeRouteQuery('set_code', newValue)
    //   this.loadData()
    //   if (newValue !== null && newValue !== '') {
    //     this.item_code = ''
    //     this.subs_code = ''
    //   }
    // },

    //定期便コード
    // subs_code(newValue) {
    //   this.changeRouteQuery('subs_code', newValue)
    //   this.loadData()
    //   if (newValue !== null && newValue !== '') {
    //     this.set_code = ''
    //     this.item_code = ''
    //   }
    // },

    selectedList(after) {
      this.addProList = after
      // console.log('@@@',after)
    },

    dialog(newValue) {
      // console.log('step,select',this.selectedList)
      if (newValue && this.list) {
        this.selectedList = this.list.map(l => {
          let item = {
          }
          item = this.ProductList.find(tl => tl.product_code === l.product_code)
          if (this.selectedList.length>0) {
            item.order_qty = l.order_qty
          }

          return item
        })
        // console.log('selectedList',this.selectedList)
      }
    },
  },
  created() {
    this.loadData()
    this.loadSetCodes()
    this.loadSubscriptionCodes()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('productStore', ['loadProductList']),
    ...mapActions('itemStore', ['loadItemCodes']),
    ...mapActions('setStore', ['loadSetCodes']),
    ...mapActions('subscriptionStore', ['loadSubscriptionCodes']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadProductList({
        product_type: 3
      }).then(() => {
        // console.log('this.ProductList',this.ProductList)
        // console.log('load successs', this.tableList)
        this.selectedList = this.list.map(l => {
          let item = {
          }
          item = this.ProductList.find(tl => tl.product_code === l.product_code)
          item.order_qty = l.order_qty

          return item
        })
        this.tableList = [
          ...this.ProductList,
        ]
        // console.log('this.selectedList',this.selectedList)
        // console.log('this.tableList',this.tableList)
        this.setOverlayStatus(false)
      })
    },
    editItem(item) {
      this.$router.push({ name: 'products-edit', params: { id: item.crushed_id, query: this.$route.query } })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({ path: this.$route.path, query })
    },
    goBack() {
      this.dialog = false
      this.$emit('add_prolist', this.addProList)
      this.showSelect = false
    },
    openForm() {
      this.dialog = true
      // console.log('this.list', this.list)
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
